<template>
  <div v-if="$ability.can('read', this.$route.meta.resource)">
    <b-overlay
      :show="show"
      rounded="sm"
    >
      <b-row>
        <b-col
          v-for="(item,index) in services"
          :key="index"
          md="6"
          lg="4"
        >
          <b-card
            :title="$t(item.name)"
            class="text-center"
          >
            <b-card-text>
              {{ $t('Edit ability') }}
            </b-card-text>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="openAbility(item)"
            >
              {{ $t('Edit') }}
            </b-button>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard, BCardText, BRow, BCol,
  BImg,
  BAvatar,
  BOverlay,
  BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
// import { useToast } from 'vue-toastification/composition'
// import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import storeModule from '@/store/root/ability'
import debounce from 'debounce'

const STORE_MODULE_NAME = 'ability'

export default {
  components: {
    BCard,
    BCardText,
    // eslint-disable-next-line vue/no-unused-components
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BImg,
    // eslint-disable-next-line vue/no-unused-components
    BAvatar,
    BOverlay,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      avatarText,
      services: [{ name: 'Root', value: 'root' }, { name: 'Franchise', value: 'franchise' }, { name: 'Company', value: 'company' }, { name: 'Delivery', value: 'delivery' }, { name: 'Hero', value: 'hero' }],
      show: false,
    }
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)

    this.show = false
    this.typing = debounce(this.typing, 500)
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
  },
  setup() {
    return { STORE_MODULE_NAME }
  },
  methods: {
    openAbility(data) {
      this.show = true

      this.$bvModal
        .msgBoxConfirm(`${this.$t('Do you want to enter')} ${this.$t('ability')}?`, {
          title: this.$t('Please Confirm'),
          cancelVariant: 'outline-secondary',
          okVariant: 'success',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          centered: true,
        })
        .then(value => {
          this.show = false

          if (value === true) {
            this.$router.push({ path: `/root/ability/${data.value}` })
          }
          //  else {
          //   this.showToast(
          //     'warning',
          //     'top-right',
          //     `${this.$t('DELETE_CANCEL')}!`,
          //     'AlertCircleIcon',
          //     this.$t('DELETE_CANCEL'),
          //   )
          // }
        })
    },
  },
}
</script>
